import * as React from 'react'
import { Link, graphql } from 'gatsby'

export const Footer = ({ footer }) => {

  const footerLinks = footer
    ? footer.footer_links.map((footerLink, index) => (
      <a key={index} className="col-12 col-lg-6 nav-link h4 mt-0 mb-3 active" aria-current="page" href={footerLink.link.url}>{footerLink.label}</a>
      ))
    : null


  return (
    <footer id="footer">
      <div className="container py-5">
        <div className="row gy-4 justify-content-between">
          <div className="col-12 col-md-4 text-center text-md-start d-flex justify-content-center justify-content-md-start">
            <div className="text-uppercase lh-lg" style={{width:"fit-content", fontWeight:"600"}}>
              <img src="https://zum-rad-bb.cdn.prismic.io/zum-rad-bb/c0a2e098-9e1a-47ff-9404-a3c750ab61c3_logo-fff.svg" alt="" height="60" className="mb-4"/><br/>
              Wettbachstrasse 6 <br/>
              71063 Sindelfingen<br/>
              Tel.: <a href="tel:07031735253" className=" text-decoration-none">07031-735253</a><br/>
              Email: <a href="mailto:zumrad@outlook.de" className=" text-decoration-none">zumrad@outlook.de</a><br/>
              <a class="text-decoration-none" aria-current="page" href="/impressum">IMPRESSUM</a> / <a class="text-decoration-none" aria-current="page" href="/datenschutz">DATENSCHUTZ</a>
            </div>
          </div>
          <div className="col-12 col-md-4 text-center text-md-start d-flex justify-content-center justify-content-md-end">
            <div className="text-uppercase lh-lg" style={{width:"fit-content", fontWeight:"600"}}>
              <table>
                <tbody>
                  <tr>
                    <td className="pe-2">MO:</td>
                    <td>RUHETAG</td>
                  </tr>
                  <tr>
                    <td className="pe-2">DI-DO:</td>
                    <td>11:30 - 14:30 / 17:00 - 22:00</td>
                  </tr>
                  <tr>
                    <td className="pe-2">FR:</td>
                    <td>11:30 - 14:30 / 17:00 - 23:00</td>
                  </tr>
                  <tr>
                    <td className="pe-2">SA:</td>
                    <td>17:00 - 23:00</td>
                  </tr>
                  <tr>
                    <td className="pe-2">SO:</td>
                    <td>11:30 - 14:30 / 17:00-22:00</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <hr className="my-5"/>
        <div className="text-center">
          <div className="text-uppercase fw-bold">Design & Umsetzung <a href="https://city-eats.de/" target="_blank" className="text-primary text-decoration-none">City-Eats.de</a></div>
        </div>
      </div>
    </footer>
  )
}

export const query = graphql`
  fragment FooterFragment on PrismicFooter {
    id
    type
    data {
      copyright {
        richText
        text
      }
      footer_links {
        label
        link {
          url
        }
      }
    }
  }
`