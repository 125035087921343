import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'

import { Layout } from '../components/Layout'
import { components } from '../slices'

const PageTemplate = ({ data }) => {
  if (!data) return null

  const pageContent = data.prismicPage
  const page = pageContent.data || {}

  const topMenu = data.prismicTopMenu || {}
  const footer = data.prismicFooter || {}

  const seo = {
    title: page.seo_title,
    description: page.seo_description,
    keywords: page.seo_keywords,
    no_robots: page.no_robots,
  }

  const { lang, type, url } = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }
  
  return (
    <Layout topMenu={topMenu.data} footer={footer.data} activeDocMeta={activeDoc} seo={seo}>
      <div id="hero" className="bg-dark page-cover-bg" style={{backgroundImage: "url(" + page.cover.url + ")"}}>
        <div className="cover-blackout h-100 d-flex align-items-end">
          <div className="container ">
            <h1 className="text-center card-title text-white" style={{margin:"0 0 20px 0"}}>{page.title}</h1>
          </div>
        </div>
      </div>
      <SliceZone slices={page.body} components={components} />
    </Layout>
  )
}

export const query = graphql`
  query pageQuery($id: String)  {
    prismicPage(id: { eq: $id }) {
      _previewable
      url
      uid
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        cover {
          url
        }
        title
        seo_title
        seo_description
        seo_keywords
        no_robots
        
        body {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...PageDataBodyTitleAndText
        }
      }
    }
    prismicTopMenu {
      ...TopMenuFragment
    }
    prismicFooter {
      ...FooterFragment
    }
  }
`

export default withPrismicPreview(PageTemplate)
