import * as React from 'react'
import { graphql } from 'gatsby'

export const Iframe = ({ slice }) => {
  const {pretitle, title, link} = slice.primary

  const ref = React.useRef();
  const [height, setHeight] = React.useState("0px");
  const onLoad = () => {
    setHeight(ref.current.contentWindow.document.body.scrollHeight + "px");
  };

  return (
    <div id="tischreservierung" className="bg-white py-70 border-top">
      <div className="container">
        <h2 className="mb-4 text-center"><span className="card-pretitle">{pretitle}</span><span className="card-title">{title}</span></h2>
        <iframe className="w-100" height={400} ref={ref} onload={onLoad} src={link.url}></iframe>
      </div>
    </div>
    
  )
}

export const query = graphql`
  fragment HomepageDataBodyIframe on PrismicHomepageDataBodyIframe {
    primary {
      pretitle
      title
      link {
        url
      }
    }
  }
`
