import * as React from 'react'
import {useEffect} from 'react';
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import { createBootstrapComponent } from 'react-bootstrap/esm/ThemeProvider';

export const CardAndImage = ({ slice, index }) => {
  const {reverse, anchor, pretitle, title, subtitle, text, img} = slice.primary

  useEffect(() => {
    const viewportHeight = window.innerHeight;
    const parallaxCard = document.getElementById(`parallax-${index}-card`)
    //console.log("viewportHeight", viewportHeight);
    //console.log("parallaxCard.offsetHeight", parallaxCard.offsetHeight);

    /*on load*/
    let scrollRect = document.getElementById(`parallax-${index}`).getBoundingClientRect()
    let cardPosition = (scrollRect.bottom-viewportHeight)/4+parallaxCard.offsetHeight*0.65;
    parallaxCard.style.transform = `translate3d(0, ${cardPosition}px, 0)`;

    /*on scroll*/
    document.addEventListener('scroll', function () {
      scrollRect = document.getElementById(`parallax-${index}`).getBoundingClientRect()
      //console.log("t", scrollRect.top);
      //console.log("b", scrollRect.bottom);
      cardPosition = (scrollRect.bottom-viewportHeight)/4+parallaxCard.offsetHeight*0.65;
      //console.log("cardPosition", cardPosition);

      if (matchMedia('only screen and (min-width: 992px)').matches) {
        if (scrollRect.top < viewportHeight && scrollRect.bottom > 0) {
          
          parallaxCard.style.transform = `translate3d(0, ${cardPosition}px, 0)`;
          //animatedCardImg.style.transformStyle = `preserve-3d`;
          //animatedCardImg.style.transform = `perspective(${1000 - (65 * (scrollPercent - startMovingPoint))}px) rotateY(-${.5 * (scrollPercent - startMovingPoint)}deg)`;
          //animatedCard.style.transform = `translate3d(${(scrollPercent - startMovingPoint)*24}px, 0, 0) scale3d(${1-((scrollPercent - startMovingPoint)/80)}, ${1-((scrollPercent - startMovingPoint)/80)}, ${1-((scrollPercent - startMovingPoint)/80)})`;
          return;
        } else {
          parallaxCard.style.transform = `initial`;
        }
      }
    });
  }, []);

  return (
    <div id={`parallax-${index}`} className="bg-white border-top">
      <div id={anchor} className="container">
        <div className={`d-flex flex-column ${reverse ? `align-items-start` : `align-items-end`}`}>
          <div id={`parallax-${index}-card`} className="bg-white p-40 border parallax-card-col" style={reverse ? {right:"90px"} : {left:"90px"}}>
            <h2 className="m-0"><span className="card-pretitle">{pretitle}</span><span className="card-title">{title}</span></h2>
            <div className="card-subtitle">{subtitle}</div>
            <div className="">
              <PrismicRichText field={text.richText} />
            </div>
          </div>
          <img src={img.url} className="parallax-img-col"/>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyCardAndImage on PrismicHomepageDataBodyCardAndImage {
    primary {
      reverse
      anchor
      pretitle
      title
      subtitle
      text {
        richText
      }
      img {
        url
      }
    }
  }
`
