import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'

export const Hero = ({ slice }) => {
  const {title_pt_1, title_pt_2, title_pt_3, logo, cover} = slice.primary
  return (
    <div id="hero" className="bg-dark main-cover-bg" style={{backgroundImage: "url(" + cover.url + ")"}}>
      <div className="main-cover-blackout h-100 d-flex align-items-center">
        <div className="container text-center px-4">
            <img src={logo.url} style={{maxWidth:"640px",filter:"drop-shadow(0px 4px 16px rgb(0 0 0 / 0.25))"}}/>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyHero on PrismicHomepageDataBodyHero {
    primary {
      logo {
        url
      }
      cover {
        url
      }
    }
  }
`
