import React, { useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import { PrismicLink, PrismicText } from '@prismicio/react'
import { StaticImage } from 'gatsby-plugin-image'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';

export const TopMenu = ({ topMenu, activeDocMeta }) => {
  const [fixedNav, setFixedNav] = useState(false)
  const navRef = React.useRef()
  navRef.current = fixedNav
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 50
      if (navRef.current !== show) {
        setFixedNav(show)
      }
    }
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const renderedMenuLinks = topMenu
    ? topMenu.menu_links.map((menuLink, index) => (
      <Nav.Link key={`top-nav-${index}`} href={menuLink.anchor ? "/#"+menuLink.anchor : menuLink.link.url}>{menuLink.label}</Nav.Link>
      ))
    : null

  return (
    <>
      {['lg'].map((expand) => (
        <Navbar key={expand} expand={expand} collapseOnSelect fixed="top" variant={`${fixedNav ? `` : `dark`}`} className={`top_bar ${fixedNav ? `` : `ontop`}`} style={fixedNav ? {height:"64px"} : {height:"96px"}}>
          <Container>
            <Navbar.Brand href="/">
              <img src={fixedNav ? topMenu.logo.url : topMenu.logo_light.url} alt="" height={fixedNav ? "32" : "42"}/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`}/>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              restoreFocus={false}
              scroll={true}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  {topMenu.title_mobile}
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  {renderedMenuLinks}
                  <Nav.Link href="#tischreservierung" className="py-2 py-lg-0"><div className="ms-lg-3 btn btn-sm btn-primary text-white">Tischreservierung</div></Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  )
}

export const query = graphql`
  fragment TopMenuFragment on PrismicTopMenu {
    _previewable
    type
    lang
    data {
      logo_light {
        url
      }
      logo {
        url
      }
      title_mobile
      menu_links {
        label
        link {
          id
          url
        }
        anchor
      }
    }
  }
`
