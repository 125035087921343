import * as React from 'react'
import { graphql } from 'gatsby'
import Carousel from 'react-bootstrap/Carousel';
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import { Gallery, Item } from 'react-photoswipe-gallery'
import { useImageSize } from 'react-image-size';

export const ImgGallery = ({ slice }) => {
  const galleryImages = slice.items.map((items, index) => {
    const imgUrl = items.img.url;
    const [dimensions] = useImageSize(imgUrl);
    return (
      <div key={index} className="col-3" style={{cursor:"pointer",aspectRatio:"1"}}>
        <Gallery>
          <Item
            original={imgUrl}
            thumbnail={imgUrl}
            width={dimensions?.width}
            height={dimensions?.height}
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src={imgUrl}
                alt={""}
                className="image-cover"
              />
            )}
          </Item>
        </Gallery>
      </div>
    );
  });

  return (
    <div>
      <div className="row g-0">
        {galleryImages}
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyImgGallery on PrismicHomepageDataBodyImgGallery {
    items {
      img {
        url
      }
    }
  }
`
