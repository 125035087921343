import React, { useState, useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import { useForm } from "react-hook-form";
import Modal from 'react-bootstrap/Modal';
import { DatePicker, TimePicker } from 'antd';
import moment from 'moment';
import de_DE from 'antd/lib/date-picker/locale/de_DE';
import { createBootstrapComponent } from 'react-bootstrap/esm/ThemeProvider';
import {callHook} from '../utils/utils';
import settings from '../settings.json';

export const Rental = ({ slice, index }) => {
  const {reverse, pretitle, title, subtitle, text, img} = slice.primary

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isSend, setSend] = useState(false);
  const [isError, setError] = useState(false);
  const rdate = useRef();
  const rtime = useRef();

  const { register, setValue, handleSubmit, watch, formState: { errors, isSubmitted } } = useForm({
    mode: "onChange"
  });

  const onDateChange = (date,dateString) => {
    console.log('onDateChange: ', dateString);
    rdate.current = dateString;
  }
  const onTimeChange = (time, timeString) => {
    console.log('onTimeChange: ', timeString);
    rtime.current = timeString;
  }

  const onSubmit = async (data) => {
    if (rdate.current) data.date = rdate.current;
    console.log('data: ', data);
    const url = settings.forms_webhook;
    const r = await callHook({url, uid:'zum-rad-mietraum', params:data});
    console.log(r);
    setSend(true);
  }

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current <= moment().add(-1, 'days');
  };

  const disabledHours = () => {
    var hours = [];
    for(var i =0; i < moment().hour(); i++){
        hours.push(i);
    }
    return hours;
  }

  const disabledMinutes = (selectedHour) => {
    var minutes= [];
    if (selectedHour === moment().hour()){
        for(var i =0; i < moment().minute(); i++){
            minutes.push(i);
        }
    }
    return minutes;
  }

  useEffect(() => {
    const viewportHeight = window.innerHeight;
    const parallaxCard = document.getElementById(`parallax-${index}-card`)
    //console.log("viewportHeight", viewportHeight);
    //console.log("parallaxCard.offsetHeight", parallaxCard.offsetHeight);

    /*on load*/
    let scrollRect = document.getElementById(`parallax-${index}`).getBoundingClientRect()
    let cardPosition = (scrollRect.bottom-viewportHeight)/4+parallaxCard.offsetHeight*0.65;
    parallaxCard.style.transform = `translate3d(0, ${cardPosition}px, 0)`;

    /*on scroll*/
    document.addEventListener('scroll', function () {
      scrollRect = document.getElementById(`parallax-${index}`).getBoundingClientRect()
      //console.log("t", scrollRect.top);
      //console.log("b", scrollRect.bottom);
      cardPosition = (scrollRect.bottom-viewportHeight)/4+parallaxCard.offsetHeight*0.65;
      //console.log("cardPosition", cardPosition);

      if (matchMedia('only screen and (min-width: 992px)').matches) {
        if (scrollRect.top < viewportHeight && scrollRect.bottom > 0) {
          
          parallaxCard.style.transform = `translate3d(0, ${cardPosition}px, 0)`;
          //animatedCardImg.style.transformStyle = `preserve-3d`;
          //animatedCardImg.style.transform = `perspective(${1000 - (65 * (scrollPercent - startMovingPoint))}px) rotateY(-${.5 * (scrollPercent - startMovingPoint)}deg)`;
          //animatedCard.style.transform = `translate3d(${(scrollPercent - startMovingPoint)*24}px, 0, 0) scale3d(${1-((scrollPercent - startMovingPoint)/80)}, ${1-((scrollPercent - startMovingPoint)/80)}, ${1-((scrollPercent - startMovingPoint)/80)})`;
          return;
        } else {
          parallaxCard.style.transform = `initial`;
        }
      }
    });
  }, []);

  return (
    <>
      <div id={`parallax-${index}`} className="bg-white border-top">
        <div id="raumlichkeiten" className="container">
          <div className={`d-flex flex-column ${reverse ? `align-items-start` : `align-items-end`}`}>
            <div id={`parallax-${index}-card`} className="bg-white p-40 border parallax-card-col" style={reverse ? {right:"90px"} : {left:"90px"}}>
              <h2 className="m-0" style={{wordBreak:"break-all"}}><span className="card-pretitle">{pretitle}</span><span className="card-title">{title}</span></h2>
              <div className="card-subtitle">{subtitle}</div>
              <div className="">
                <PrismicRichText field={text.richText} />
              </div>
              <div className="btn btn-dark" onClick={handleShow}>Zum Anfrageformular</div>
            </div>
            <img src={img.url} className="parallax-img-col"/>
          </div>
        </div>
      </div>
      <Modal size="lg" show={show} onHide={handleClose} animation={false} centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          { isSend ?
            <div className="text-center">
              <h3>Danke!</h3>
              <p>Wir werden Sie in Kürze kontaktieren.</p>
              <div className="col-auto">
                <button onClick={()=>setSend(false)} className={"btn btn-dark"}>{"Zurück"}</button>
              </div>
            </div>
          :
            <form id="corners-sifi-reservation" className="text-center" onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-4">
                <div className="col-12 col-md-6 col-lg-4">
                  <input
                    id="reservation-form-persons-num"
                    placeholder="Personenzahl"
                    className={'form-control'}
                    {...register('persons', {required: false})}
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <DatePicker
                    className="form-select"
                    disabledDate={disabledDate}
                    locale={de_DE}
                    onChange={onDateChange}
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <input
                    id="reservation-form-name"
                    placeholder="Vor- und Zuname"
                    className={'form-control'}
                    {...register('name', {required: false})}
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <input
                    id="reservation-form-email"
                    type="email"
                    placeholder="E-Mail"
                    className={'form-control'}
                    {...register('email', {required: false})}
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <input
                    id="reservation-form-phone"
                    type="tel"
                    placeholder="Telefonnummer"
                    className={'form-control'}
                    required
                    {...register('phone', {required: true})}
                  />
                  <div className="invalid-feedback" wfd-invisible="true">{'Required!'}</div>
                </div>
                <div className="col-12">
                  <textarea
                    id="reservation-form-message"
                    placeholder="Nachricht"
                    className={'form-control'}
                    {...register('text', {required: false})}
                  />
                </div>
              </div>
              <div className="text-center">
                <button className="btn btn-dark mt-4" type="submit">Anfrage absenden</button>
              </div>
            </form>
          }
        </Modal.Body>
      </Modal>
    </>
  )
}

export const query = graphql`
  fragment HomepageDataBodyRental on PrismicHomepageDataBodyRental {
    primary {
      reverse
      pretitle
      title
      subtitle
      text {
        richText
      }
      img {
        url
      }
    }
  }
`
