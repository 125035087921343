import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { TopMenu } from './TopMenu'
import { Footer } from './Footer'

export const Layout = ({ children, topMenu, footer, seo, activeDocMeta }) => {
  const queryData = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          title
          description
          keywords
          siteUrl
          metaImage
        }
      }
    }
  `)

const lang = 'en'
const title = /*seo.title ||*/ queryData.site.siteMetadata.title 
const metaDescription = /*seo.description ||*/ queryData.site.siteMetadata.description
const keywords = /*seo.keywords ||*/ queryData.site.siteMetadata.keywords

const meta = [{
      name: `description`,
      content: metaDescription,
    },{
      name: "keywords",
      content: keywords,
    },{
      property: `og:title`,
      content: title,
    },{
      property: `og:description`,
      content: metaDescription,
    },{
      property: `og:type`,
      content: `website`,
    },{
      name: `twitter:creator`,
      content: queryData.site.siteMetadata.author,
    },{
      name: `twitter:title`,
      content: title,
    },{
      name: `twitter:description`,
      content: metaDescription,
    },{
      name: `theme-color`,
      content: '#cfa670',
    }
  ]

  /*const noRobots = seo.no_robots !== undefined && seo.no_robots === true
  if (noRobots) {
    meta.push({
      name: "robots",
      content: "noindex",
    })
  }*/

  let _metaImage = [{
      name: "twitter:card",
      content: "summary",
    },
  ]
  const {siteUrl} = queryData.site.siteMetadata
  const metaImage = /*seo.metaImage ||*/ queryData.site.siteMetadata.metaImage

  if (metaImage) {
    const image = metaImage.startsWith('http') ? `${metaImage}` : `${siteUrl}${metaImage}`
    _metaImage = [{
        property: "og:image",
        content: image,
      },{
        property: "og:image:width",
        content: metaImage.width,
      },{
        property: "og:image:height",
        content: metaImage.height,
      },{
        name: "twitter:card",
        content: "summary_large_image",
      },
    ]
  }

  console.log("SEO", seo)

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        meta={[
          ...meta,
        ].concat(
          _metaImage
        )}
      >
      </Helmet>
      <section className="bg-white d-flex flex-column justify-content-between" style={{height:"100vh"}}>
        {topMenu && <TopMenu topMenu={topMenu} activeDocMeta={{}} />}
        <main className={"flex-fill d-flex flex-column"}>{children}</main>
        <Footer footer={footer}/>
      </section>
    </>
  )
}
