import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'

export const ImageFullWidth = ({ slice, index }) => {

  useEffect(() => {
    const viewportHeight = window.innerHeight;
    const parallaxImg = document.getElementById(`parallax-${index}`)

    /*on load*/
    let scrollRect = document.getElementById(`parallax-${index}`).getBoundingClientRect()
    let position = (scrollRect.bottom-viewportHeight)/1-parallaxImg.offsetHeight;
    if (matchMedia('only screen and (min-width: 992px)').matches) {
      parallaxImg.style.backgroundPosition = `center ${position}px`;
    } else {
      parallaxImg.style.backgroundPosition = `center center`;
    }

    /*on scroll*/
    document.addEventListener('scroll', function () {
      scrollRect = document.getElementById(`parallax-${index}`).getBoundingClientRect()
      position = (scrollRect.bottom-viewportHeight)/10-parallaxImg.offsetHeight*3/2;

      if (matchMedia('only screen and (min-width: 992px)').matches) {
        if (scrollRect.top < viewportHeight && scrollRect.bottom > 0) {   
          parallaxImg.style.backgroundPosition = `center ${position}px`;
          return;
        } else {
          parallaxImg.style.backgroundPosition = `center center`;
        }
      }
    });
  }, []);

  return (
    <div id={`parallax-${index}`} style={{backgroundImage: "url(" + slice.primary.img.url + ")", backgroundSize:"cover", backgroundRepeat: "no-repeat", backgroundPosition: "center center", height:"400px", maxHeight:"25vh"}}>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyImageFullWidth on PrismicHomepageDataBodyImageFullWidth {
    primary {
      img {
        url
      }
    }
  }
`
