import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'

import { Layout } from '../components/Layout'
import { components } from '../slices'

const HomepageTemplate = ({ data }) => {
  if (!data) return null

  const homepage = data.prismicHomepage || {}
  const topMenu = data.prismicTopMenu || {}
  const footer = data.prismicFooter || {}

  const seo = {
    title: homepage.data.seo_title,
    description: homepage.data.seo_description,
    keywords: homepage.data.seo_keywords,
  }

  const { lang, type, url } = homepage || {}
  const alternateLanguages = homepage.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  return (
    <Layout topMenu={topMenu.data} footer={footer.data} activeDocMeta={activeDoc} seo={seo || {}}>
      <SliceZone slices={homepage.data.body} components={components} />
    </Layout>
  )
}

export const query = graphql`
  query homepageQuery {
    prismicHomepage {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {
        seo_title
        seo_description
        seo_keywords

        body {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ...HomepageDataBodyHero
          ...HomepageDataBodyCardAndImage
          ...HomepageDataBodyMenu
          ...HomepageDataBodyIframe
          ...HomepageDataBodyImageFullWidth
          ...HomepageDataBodyRental
          ...HomepageDataBodyNewsSubscription
          ...HomepageDataBodyImgGallery
        }
      }
    }
    prismicTopMenu {
      ...TopMenuFragment
    }
    prismicFooter {
      ...FooterFragment
    }
  }
`

export default withPrismicPreview(HomepageTemplate)
