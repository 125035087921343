import { Hero } from './Hero'
import { CardAndImage } from './CardAndImage'
import { Menu } from './Menu'
import { Iframe } from './Iframe'
import { ImageFullWidth } from './ImageFullWidth'
import { Rental } from './Rental'
import { NewsSubscription } from './NewsSubscription'
import { TitleAndText } from './TitleAndText'
import { ImgGallery } from './ImgGallery'

/*import { MainCover } from './MainCover'
import { Menu } from './Menu'
import { ReservationForm } from './ReservationForm'
import { TitleAndText } from './TitleAndText'
import { TwoTitles } from './TwoTitles'
import { ImgGallery } from './ImgGallery'
import { Title } from './Title'
import { MainEvents } from './MainEvents'
import { EventInfo } from './EventInfo'*/

export const components = {
  hero: Hero,
  card_and_image: CardAndImage,
  menu: Menu,
  iframe: Iframe,
  image_full_width: ImageFullWidth,
  rental: Rental,
  news_subscription: NewsSubscription,
  title_and_text: TitleAndText,
  img_gallery: ImgGallery,

  /*event_info: EventInfo,
  main_cover: MainCover,
  menu: Menu,
  main_events: MainEvents,
  reservation_form: ReservationForm,
  title_and_text: TitleAndText,
  two_titles: TwoTitles,
  img_gallery: ImgGallery,
  title: Title,*/
}
