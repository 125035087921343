import * as React from 'react'
import { graphql } from 'gatsby'
import { DatePicker, TimePicker } from 'antd';
import moment from 'moment';
import de_DE from 'antd/lib/date-picker/locale/de_DE';
import { useState, useRef } from 'react';
import { useForm } from "react-hook-form";
import {callHook} from '../utils/utils';
import settings from '../settings.json';

export const NewsSubscription = ({ slice, index }) => {
  const {pretitle, title, cover} = slice.primary

  const [isSend, setSend] = useState(false);
  const [isError, setError] = useState(false);

  const { register, setValue, handleSubmit, watch, formState: { errors, isSubmitted } } = useForm({
    mode: "onChange"
  });
  
  const isFirst = index === 0;

  if (typeof window !== 'undefined'){
    const pageUrl = window.location;
    //console.log('pageUrl: ', pageUrl);
  }  

  const onSubmit = async (data) => {
    console.log('data: ', data);
    const url = settings.forms_webhook;
    const r = await callHook({url, uid:'zum-rad-newsletter', params:data});
    console.log(r);
    setSend(true);
  }

  return (
    <div style={{backgroundImage: "url(" + cover.url + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center center"}}>
      <div className="cover-blackout">
			  <div className="container py-70">
          <h2 className="text-white text-center" style={{margin:"0 0 24px 0"}}><span className="card-pretitle">{pretitle}</span><span className="card-title">{title}</span></h2>
          <div className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-5 mx-auto">
            { isSend ?
              <div className="text-white text-center">
                {!isError && (
                <>
                  <h3>Thank You!</h3>
                </>
                )}
                {isError && (
                <>
                  <h3>An error has occurred</h3>
                </>
                )}
                <div className="col-auto">
                  <button onClick={()=>setSend(false)} className={"btn text-uppercase btn-primary"}>{isError ? "Zurück" : "Zurück"}</button>
                </div>
              </div>
              :
              <form id="yohn-contact-us" className="text-center" onSubmit={handleSubmit(onSubmit)}>
                <div className="row g-3 px-4">
                  <div className="col-12 col-lg">
                    <input
                      id="email"
                      placeholder="Your email address"
                      className={'form-control' + " " + (errors.email && "is-invalid")}
                      {...register('email', {required: true})}
                    />
                    <div className="invalid-feedback" wfd-invisible="true">{'Invalid email address'}</div>
                  </div>
                  <div className="col-12 col-lg-auto">
                    <button className="btn btn-primary text-uppercase" type="submit">Abonnieren</button>
                  </div>
                </div>
              </form>
            }
          </div>
        </div>
			</div>
		</div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyNewsSubscription on PrismicHomepageDataBodyNewsSubscription {
    primary {
      pretitle
      title
      cover {
        url
      }
    }
  }
`
