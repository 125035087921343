import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import Accordion from 'react-bootstrap/Accordion';

export const Menu = ({ slice }) => {
  const [menus, setMenus] = React.useState([]);
  const {pretitle, title, text} = slice.primary

  React.useEffect(() => {
    const sliceItems =  slice.items || [];
    const _menu = sliceItems.map(menu => {
      const doc = menu.menu_item?.document || {};
      const body = doc.data?.body || [];
      if (body.length<1) return;
      const firstBody = body[0];
      const title = firstBody.primary.title;
      return {
        title,
        items: firstBody.items,
      };
    });
    setMenus(_menu);
  }, [])

  const menu = menus.map((item, index) => {
    
    const itemCount = item.items.length

    return (
      <Accordion key={index} className="mt-3">
      <Accordion.Item eventKey={index} >
        <Accordion.Header as="h3"><span className="h5 mb-0">{item.title}</span></Accordion.Header>
        <Accordion.Body>
          {item.items.map((item, index) => (
            <div key={index} className="pt-3">
              <h5 className="menu-dish-title d-flex justify-content-between">
                <span>{`${item.title} `}</span>
                {item.price &&<span style={{fontSize:"17px"}}>{`${Number(item.price).toFixed(2)} €`}</span>}
              </h5>
              <div style={{fontSize:"13px"}}>
              <PrismicRichText field={item.desc.richText}/>
              </div>
              {(index + 1 != itemCount) &&
                <hr style={{opacity:".1"}}/>
              } 
            </div>
          ))}
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
    )
  })

  return (
    <div id="speisekarte" className="bg-white border-top">
      <div className="container py-70">
        <div className="bg-dark text-white p-30">
          <h2 className="m-0"><span className="card-pretitle">{pretitle}</span><span className="card-title">{title}</span></h2>
          <div className="">
            <PrismicRichText field={text.richText} />
          </div>
        </div>

          {menu}

      </div>
    </div>
  )
}

  /*const menu = menus.map((item, index) => {
    return (
      <div key={index} className="bg-white border p-30">
        <h2 className="menu-title">{item.title}</h2>
        
        {item.items.map((item, index) => (
          <div key={index} className="mt-30">
                <h5 className="menu-dish-title d-flex justify-content-between">
                  <span>{`${item.title} `}</span>
                  <span style={{fontSize:"17px"}}>{`${item.price}€`}</span>
                </h5>
                <div style={{fontSize:"13px"}}>
                <PrismicRichText field={item.desc.richText}/>
                </div>
          </div>
        ))}
      </div>
    )
  })

  return (
    <div className="bg-white border-top">
      <div className="container py-70">
        <ResponsiveMasonry columnsCountBreakPoints={{0: 1, 992: 2}} >
          <Masonry gutter="24px">
            <div className="bg-dark text-white p-30">
              <h2 className="m-0"><span className="card-pretitle">{pretitle}</span><span className="card-title">{title}</span></h2>
              <div className="">
                <PrismicRichText field={text.richText} />
              </div>
            </div>
            {menu}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    </div>
  )
}*/

export const query = graphql`
  fragment HomepageDataBodyMenu on PrismicHomepageDataBodyMenu {
    primary {
      pretitle
      title
      text {
        richText
      }
    }
    items {
      menu_item {
        id
        link_type
        document {
          ... on PrismicMenu {
            id
            type
            data {
              body {
                ... on PrismicMenuDataBodyMenuItem {
                  id
                  slice_type
                  primary {
                    title
                  }
                  items {
                    title
                    desc {
                      richText
                    }
                    price
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
